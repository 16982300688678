import {
  AfterContentChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit
} from '@angular/core';
import {interval, Subject, takeUntil} from "rxjs";
import {TranslateService} from "@ngx-translate/core";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {differenceInMinutes, format, isAfter, isEqual, parseISO, set} from 'date-fns';
import {ActivatedRoute, Router} from "@angular/router";
import {StorageService} from "../../services/storage.service";
import {SlotsService} from "../../services/api/slots.service";
import {ReducedSlot} from "../../entities/ReducedSlot.entity";
import {Backend} from "@generated-backend-types";
import {formatDate} from "@angular/common";
import SlotLanguageEnum = Backend.App.Enums.Bookings.SlotLanguageEnum;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('fade', [
      state('visible', style({opacity: 1})),
      state('hidden', style({opacity: 0})),
      transition('visible => hidden', animate('.3s ease-out')),
      transition('hidden => visible', animate('.3s ease-in')),
    ]),
  ]
})
export class HomeComponent implements OnInit, OnDestroy, AfterContentChecked {
  public mode: 'landscape' | 'skyscraper' | 'match-your-style' = 'landscape';

  public contentVisible: boolean = true;
  public currentLang: string = this.translate.defaultLang;

  public slots: ReducedSlot[] = [];

  public currentTime?: Date;
  public currentTimeString?: String;

  public minutesTillFirstSlotStart?: number;

  private onDestroy$: Subject<void> = new Subject();

  public ngOnInit() {
    if (this.activatedRoute.snapshot.queryParams && this.activatedRoute.snapshot.queryParams.hasOwnProperty('token')) {
      this.storageService.set('token', this.activatedRoute.snapshot.queryParams['token'])
      this.router.navigate([], {queryParams: {}})
    }

    if (this.activatedRoute.snapshot.data && this.activatedRoute.snapshot.data.hasOwnProperty('mode')) {
      this.mode = this.activatedRoute.snapshot.data['mode'];
    }

    this.loadSlots();

    // language change interval => every 7 sec
    interval(1000 * 7).pipe(
      takeUntil(this.onDestroy$)
    ).subscribe({
      next: () => {
        this.contentVisible = false;
        this.cdr.markForCheck();
      }
    });

    // time updater interval => every 60 sec
    interval(1000 * 60).pipe(
      takeUntil(this.onDestroy$)
    ).subscribe({
      next: () => {
        this.loadSlots();
        this.cdr.markForCheck();
      }
    });

    // daily refresh checker => every 5 sec
    interval(1000 * 5).pipe(
      takeUntil(this.onDestroy$)
    ).subscribe({
      next: () => {
        this.dailyRefresh();
      }
    });

    // updates the clock in the top right => every second
    interval(1000).pipe(
      takeUntil(this.onDestroy$)
    ).subscribe({
      next: () => {
        this.setNowTime();
      }
    });

    this.cdr.markForCheck();
  }

  public ngOnDestroy() {
    this.onDestroy$.next()
  }

  public ngAfterContentChecked() {
    if (!this.contentVisible) {
      const timeout = 300;
      setTimeout(() => {
        this.contentVisible = true;
        this.cdr.markForCheck();
        this.translate.use(this.translate.currentLang === 'de' ? 'en' : 'de');
        this.currentLang = this.translate.currentLang;
      }, timeout)
    }
  }

  private setNowTime(): void {
    this.currentTime = new Date();
    this.currentTime.setSeconds(0, 0);
  }

  private calcMinutesTillFirstSlotStart(): void {
    if (this.currentTime && this.slots[0]) {
      this.minutesTillFirstSlotStart = differenceInMinutes(
        parseISO(this.slots[0].start_datetime),
        this.currentTime
      );
    }
  }

  private filterSlots(): void {
    const now = new Date();
    now.setSeconds(0, 0);
    this.slots = [...this.slots].filter(slot => {
      return isAfter(
        parseISO(slot.start_datetime),
        now
      );
    })
  }

  private loadSlots(): void {
    this.slotsService.index().subscribe({
      next: res => {
        this.slots = res;
        this.filterSlots();
        this.setNowTime();
        this.calcMinutesTillFirstSlotStart();
        this.cdr.markForCheck();
      }
    })
  }

  private dailyRefresh(): void {
    const now = new Date();
    now.setSeconds(0, 0);
    const dailyRefreshTime = set(new Date(), {hours: 3, minutes: 0, seconds: 0, milliseconds: 0});
    if (isEqual(now, dailyRefreshTime)) {
      window.location.reload()
    }
  }

  constructor(
    private cdr: ChangeDetectorRef,
    private translate: TranslateService,
    private activatedRoute: ActivatedRoute,
    private storageService: StorageService,
    private slotsService: SlotsService,
    private router: Router
  ) {
  }

  public readonly SlotLanguageEnum = SlotLanguageEnum;
  protected readonly formatDate = formatDate;
  protected readonly format = format;
}
